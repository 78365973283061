@charset "UTF-8";
@font-face {
  font-family: 'noto-sans-thai';
  src: url("/fonts/notosansthai-bold-webfont.woff2") format("woff2"), url("/fonts/notosansthai-bold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

/* ตัวปกติ */
@font-face {
  font-family: 'noto-sans-thai';
  src: url("/fonts/notosansthai-regular-webfont.woff2") format("woff2"), url("/fonts/notosansthai-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

@media print {
  a[href]:after {
    content: none !important;
  }
}

body {
  font-family: 'noto-sans-thai', Lato, Roboto, sans-serif;
  font-size: 165% !important;
  /*line-height: 1.8;*/
  color: #414042;
}

.headline {
  position: relative;
}

.headline:after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 50%;
  width: 90%;
  height: 6px;
  background-color: #5f9ff7;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -ms-transform: translate(-50%);
}

.headline-primary:after {
  background-color: #337ab7 !important;
}

.headline-warning:after {
  background-color: #f0ad4e !important;
}

.thumbnail-header {
  width: 100%;
  height: 4em;
  padding-top: 0.5em;
}

.thumbnail-header-2 {
  width: 4em;
  height: 4em;
  padding-top: 0.5em;
}

.headlineWarning {
  position: relative;
}

.headlineWarning:after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 50%;
  width: 100%;
  height: 6px;
  background-color: #f0ad4e;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -ms-transform: translate(-50%);
}

.uppercase {
  text-transform: uppercase;
}

h2 {
  font-size: 1.8em;
  text-transform: uppercase;
  color: #414042;
  font-weight: 400;
  margin-top: 0.2em;
  margin-bottom: 1em;
  position: relative;
}

h3 {
  font-size: 1.6em;
  color: #414042;
  font-weight: 400;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

h4 {
  font-size: 1.1em !important;
  /*    color: #5f9ff7;*/
  font-weight: 400;
}

h4.topic {
  margin-top: 1.5em !important;
}

h4.main-topic {
  font-size: 1.4em !important;
}

h4.section {
  margin-top: 0.1em;
  margin-bottom: 0.1em;
}

h5 {
  font-size: 1em !important;
  color: #5f9ff7;
  font-weight: 400;
}

ul li {
  font-size: 1em !important;
  color: #464547;
}

p {
  font-size: 0.97em;
}

hr {
  border-top: 1px solid #E8EEF2;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

table th {
  background-color: rgba(234, 234, 234, 0.3);
  border-top: 15px solid rgba(255, 255, 255, 0) !important;
  text-align: center;
}

.tab-content table th {
  border-top: 0px !important;
}

table th {
  font-size: 0.95em;
  line-height: 1.375em;
  color: #303030;
  font-weight: 400;
  margin-bottom: 30px;
}

.blueFont {
  color: #528CD3 !important;
}

.primaryFont {
  color: #337ab7 !important;
}

.greyFont {
  color: #414042 !important;
}

.exHiGreyFont {
  color: #676767 !important;
}

.hiGreyFont {
  color: #888888 !important;
}

.midGreyFont {
  color: #999999 !important;
}

.blackFont {
  color: #000 !important;
}

.warningFont {
  color: #f0ad4e !important;
}

.whiteFont {
  color: #ffffff !important;
}

.redFont {
  color: #A94442 !important;
}

.yellowFont {
  color: #8A6D3B !important;
}

.greenFont {
  color: #3C763D !important;
}

.infoFont {
  color: #5bc0de !important;
}

.infoPanelFont {
  color: #31708F !important;
}

.lightGreenFont {
  color: #B9EDB8 !important;
}

.verylightGreenFont {
  color: #DDFFDE !important;
}

.lightGreyFont {
  color: #ddd !important;
}

.midGreyFont {
  color: #ccc !important;
}

.brownFont {
  color: #8A6D3B !important;
}

a.default-link {
  color: #5F9FF7 !important;
}

a.default-link:hover {
  -webkit-text-decoration-color: #5F9FF7;
  text-decoration-color: #5F9FF7;
}

.open-close-wrapper {
  margin-bottom: 0.3em;
  clear: both;
}

.open-close-wrapper #openAllButton {
  margin-bottom: 0.3em !important;
  border: 1px solid #C9DDFF;
}

.open-close-wrapper #closeAllButton {
  margin-bottom: 0.3em !important;
}

nav .container-fluid {
  margin: 0px;
  padding: 0px;
}

.container-fluid {
  padding: 0px 1% 0px 1%;
  margin: 1em 1%;
}

div.container-fluid-section {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 2em 1.5em;
}

.bg-grey {
  background-color: #f6f6f6;
  background-clip: padding-box;
}

.bg-lightGrey {
  background-color: #f9f9f9;
  background-clip: padding-box;
}

.btn-group-lesson-menu {
  clear: left;
  padding: 0;
  margin-top: 0.2em;
}

.btn-group-lesson-menu > .btn:not(.disabled) {
  background-color: white;
  border-color: #454F54 !important;
  font-size: 1.2em;
}

.btn-group-lesson-menu > .btn.disabled {
  background-color: #4472C4;
  color: white;
  font-size: 1.4em;
  opacity: 1;
}

.btn-group-lesson-menu > .btn:not(.disabled):hover {
  background-color: #E2EFFF;
  border-color: #E2EFFF;
  color: #333;
}

.panel.panel-lesson {
  margin: 0px 5px;
  border: none;
}

.panel-lesson .panel-body {
  box-shadow: 0 2px 4px 0 rgba(175, 175, 175, 0.1), 0 4px 7px 0 rgba(175, 175, 175, 0.09);
}

.panel-lesson .panel-body ul li {
  color: #44546A;
}

.panel-lesson > .panel-heading {
  background-color: #4472C4 !important;
  border-color: #4472C4;
  border-radius: 0;
  padding: 0px;
}

.panel-lesson > .panel-heading > a > h4 {
  font-weight: normal;
  color: white !important;
  padding: 10px 10px 5px 10px;
}

a.clickable-panel {
  width: 100%;
  height: 100%;
  display: block;
}

.panel-lesson > .panel-heading > a:hover, .panel-lesson > .panel-heading > a:focus {
  text-decoration: none;
}

.panel-lesson .panel-body ul {
  padding-left: 10px;
}

.lesson-category-heading h3 {
  display: inline-block;
  background-color: #44546A;
  color: white;
  padding: 10px 13px;
  border-radius: 0px;
  margin-left: 5px;
}

.lesson-category-section {
  margin-bottom: 30px;
}

.dropdown-lesson-category-heading, .dropdown-lesson-sub-category-heading {
  display: inline-block;
  float: left;
}

.dropdown-lesson-category-heading .btn {
  background-color: #44546A;
  border-radius: 0px;
  cursor: default;
}

.dropdown-lesson-category-heading .btn h3 {
  color: white;
  margin: 8px 0px;
}

.dropdown-lesson-sub-category-heading .btn {
  background-color: #4472C4 !important;
  border-color: #4472C4;
  border-radius: 0px;
  margin-top: 0.2em;
  margin-left: 0.2em;
  cursor: default;
}

.dropdown-lesson-sub-category-heading .btn h4 {
  color: white;
  margin: 8px 0px;
}

.dropdown-lesson-subjects-wrapper {
  margin-top: 0.8em;
}

.dropdown-lesson-subjects {
  display: inline-block;
  float: left;
  margin-right: 0.5em;
}

.dropdown-lesson-subjects .btn-lesson-content {
  background-color: #5182CD !important;
  border: white;
  box-shadow: 0 2px 4px 0 rgba(94, 146, 214, 0.1), 0 3px 5px 0 rgba(94, 146, 214, 0.09);
}

.dropdown-lesson-subjects .btn-lesson-exercise {
  margin-top: 2px;
  background-color: #FFFCFD !important;
  border: white;
  box-shadow: 0 2px 4px 0 rgba(193, 158, 52, 0.2), 0 3px 5px 0 rgba(193, 158, 52, 0.19);
}

.btn.btn-lesson-content {
  white-space: normal !important;
}

.btn.btn-lesson-exercise {
  white-space: normal !important;
}

.dropdown-lesson-subjects .btn h3 {
  font-size: 2.1em;
  margin-top: 3px;
  margin-bottom: 3px;
}

.dropdown-lesson-subjects .btn-lesson-content h3 {
  color: white;
}

li.dropdown-header-content {
  background-color: #A6C8F4;
  color: white;
}

li.dropdown-header-exercise {
  background-color: #E8CDB7;
  color: white;
}

.dropdown-menu.dropdown-menu-wide {
  min-width: 100%;
}

.btn.circle {
  border-radius: 15px;
}

.wrapper-lesson-previous-next {
  clear: both;
  margin-bottom: 0.3em;
  margin-top: 1em;
}

.btn-lesson-content-exercise {
  margin: 2px;
}

.btn-lesson-menu-exercise-link {
  border-radius: 15px;
  margin: 2px;
  padding: 2px 5px 2px 5px;
  opacity: 0.65;
}

.lesson-exercise-label {
  border-radius: 15px;
  display: inline-block;
  float: left;
  font-size: 1.5em;
  margin-top: 3px;
  margin-right: 7px;
  margin-bottom: 5px;
  cursor: default;
}

h3.lesson-exercise-subject {
  color: #8A6D3B;
  display: inline-block;
  float: left;
  margin-right: 3px;
}

.lesson-exercise-number {
  border-bottom-right-radius: 15px;
  display: inline-block;
  float: left;
  font-size: 1.2em;
  margin-top: 1px;
  margin-right: 1px;
}

.btn-back-to-content {
  margin: 11px 2px 18px 2px;
  border-radius: 15px;
}

.btn-top-excercise-from-content {
  margin: 7px 2px 18px 2px;
  border-radius: 15px;
}

.lesson-wrapper {
  font-size: 1.12em;
  line-height: 1.55em;
}

.lesson-wrapper h3 {
  font-size: 1.45em !important;
  position: relative;
  display: inline-block;
  padding: 5px 9px 7px 9px;
  margin-top: 0.7em;
  margin-bottom: 0.2em;
  background-color: #5E92D6;
  color: white;
}

.lesson-wrapper h3.info {
  background-color: #5bc0de;
}

.lesson-wrapper h4:after {
  content: ' ';
  position: absolute;
  top: 0.1em;
  left: 0px;
  width: 15px;
  height: 1em;
  background-color: #5E92D6;
}

.lesson-wrapper h4 {
  font-size: 1.35em !important;
  position: relative;
  margin-left: 0em;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  padding-left: 0.85em;
}

.lesson-wrapper .well-question h4 {
  margin-top: 0.4em;
}

.lesson-wrapper .well-question h4:after {
  background-color: #74AF5B;
}

.lesson-wrapper p {
  /*text-indent: 0.5em;*/
}

.lesson-wrapper p.well {
  display: inline-block;
}

.lesson-wrapper .well-note {
  display: inline-block;
  background-color: #F7EDED;
  font-size: 0.92em;
}

.lesson-wrapper strong {
  color: #4473AF;
}

.lesson-wrapper h5 {
  font-size: 1.2em !important;
  color: #414042;
  margin-top: 0.25em;
  margin-left: 0em;
  margin-bottom: 0em;
  text-decoration: underline;
  text-decoration-color: #548DD8;
  -webkit-text-decoration-color: #548DD8;
  padding-bottom: 2px !important;
}

.lesson-wrapper .well-question {
  margin-top: 2em;
  margin-bottom: 2em;
  background-color: #DFF0D8;
}

.lesson-wrapper table {
  font-size: calc(0.7em + 0.4vw);
}

.references .lesson-wrapper table {
  font-size: calc(0.7em + 0.2vw);
}

.lesson-wrapper table ul {
  padding-left: 1em;
}

.lesson-wrapper ul, .panel.lesson-panel ul, .lesson-category-section ul {
  list-style: none;
  /* Remove default bullets */
  padding-left: 2.0em;
  position: relative;
}

.lesson-wrapper ul > li::before, .panel.lesson-panel ul > li::before, .lesson-category-section ul > li::before {
  content: " ";
  position: absolute;
  width: 0.7em;
  height: 0.7em;
  border-radius: 1em;
  line-height: 1em;
  text-align: center;
  background: #548DD8;
  margin-top: 0.5em;
  margin-left: -1em;
}

ol.multiple-level {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

ol.multiple-level > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
  margin-left: 1em;
}

ol.multiple-level > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.2em;
}

li ol.multiple-level > li {
  margin: 0;
}

li ol.multiple-level > li:before {
  content: counters(item, ".") " ";
}

ol.blueNumber {
  color: #4778B7;
}

ol.blueNumber li gr {
  color: #414042;
}

.lesson-wrapper.exercise-wrapper {
  margin-top: 0.8em;
}

.lesson-wrapper.exercise-wrapper .table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
  background-color: #FFFCED;
}

.lesson-wrapper.exercise-wrapper table.table > thead > tr > th, .lesson-wrapper.exercise-wrapper table.left-head tr th {
  background-color: #CEAE56;
  color: white;
}

.lesson-wrapper .table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
  background-color: #D9EDF7;
}

.lesson-wrapper table.table > thead > tr > th, .lesson-wrapper table.left-head tr th {
  background-color: #38A5D8;
  color: white;
}

.lesson-wrapper table.table, .lesson-wrapper table.table td, .lesson-wrapper table.table th {
  border: 1px solid #eee !important;
}

.lesson-wrapper table.table {
  background-color: white;
}

.lesson-wrapper table th {
  font-size: 1.05em;
  font-weight: 400;
}

.lesson-image {
  width: 100%;
  max-width: 550px;
  margin: auto;
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
}

.lesson-image.lesson-image-with-caption {
  margin-bottom: 0.3em;
}

.caption {
  font-size: 0.92em;
  line-height: 1.1em;
}

.lesson-image.exercise-question-image {
  clear: left;
}

.lesson-wrapper.exercise-wrapper .radio {
  margin: 0px;
  margin-left: 0.32em;
  margin-bottom: 0.3em;
}

.lesson-wrapper.exercise-wrapper .radio input {
  margin-top: 0.32em;
}

.lesson-wrapper.exercise-wrapper .choice-label {
  color: #999;
  margin-right: 0.1em;
  margin-left: 0.4em;
}

.lesson-wrapper.exercise-wrapper .choice-image {
  max-width: 100%;
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.lesson-image.lesson-image-xxs, div.radio label .choice-image.lesson-image-xxs {
  max-width: 200px;
}

.lesson-image.lesson-image-xs, div.radio label .choice-image.lesson-image-xs {
  max-width: 300px;
}

.lesson-image.lesson-image-sm, div.radio label .choice-image.lesson-image-sm {
  max-width: 400px;
}

.lesson-image.lesson-image-lg, div.radio label .choice-image.lesson-image-lg {
  max-width: 700px;
}

.lesson-image.lesson-image-xl, div.radio label .choice-image.lesson-image-xl {
  max-width: 1024px;
}

.lesson-image.lesson-image-xxl, div.radio label .choice-image.lesson-image-xxl {
  max-width: 1366px;
}

.exercise-wrapper .correct-answer {
  width: 1.6em;
  height: 1.6em;
  border-radius: 0.8em;
  box-shadow: 0 1px 2px 0 rgba(95, 163, 66, 0.8), 0 0px 0px 0 rgba(95, 163, 66, 0.2);
  position: absolute;
  top: 0.1em;
  left: -0.25em;
  background-color: #DFF0D8;
  display: none;
}

.exercise-wrapper .pre-question {
  font-size: 1.1em;
}

.exercise-wrapper .media {
  box-shadow: 0 1px 2px 0 rgba(211, 188, 137, 0.3), 0 0px 0px 0 rgba(211, 188, 137, 0.05);
  border-radius: 15px;
  padding: 0.3em;
  margin-bottom: 2em;
}

.exercise-wrapper .media .media-body {
  padding-top: 5px;
  position: relative;
}

.exercise-wrapper .media .media-body .marked-sign {
  font-size: 2em;
  position: absolute;
  right: 0;
  top: 0.1em;
  display: none;
}

.exercise-wrapper .media .media-body .glyphicon-ok-sign {
  color: #5FD32A;
}

.exercise-wrapper .media .media-body .glyphicon-remove-sign {
  color: #DD8B8B;
}

.exercise-wrapper .media .media-body .glyphicon-minus-sign {
  color: #819DA8;
}

.exercise-wrapper .media .media-body .exercise-question {
  color: #8A6D3B;
  font-size: 1.07em;
  clear: left;
}

.exercise-wrapper .media .media-body div.exercise-question-image img {
  margin-bottom: 1em;
}

.exercise-wrapper .media .solution {
  background-color: #DFF0D8;
  display: none;
  margin: 1em 0.2em 0.2em 0.2em;
}

.exercise-wrapper .media .solution .solution-text {
  margin-top: 0.4em;
}

.exercise-wrapper .media .label {
  font-size: 1.0em;
}

.exercise-wrapper table.table tr th.table-choice {
  text-align: left !important;
}

.exercise-wrapper #score_calc {
  background-color: #D9EDF7;
  color: #437389;
}

.exercise-wrapper #see_solution {
  background-color: #DFF0D8;
  color: #527A42;
}

.exercise-wrapper #reset {
  background-color: #F2DEDE;
  color: #8C4444;
}

.exercise-wrapper #back_to_top {
  background-color: #FCF8E3;
  color: #847943;
}

#exercise-button-wrapper {
  margin-top: 1em;
}

.navbar {
  /*  padding-right:0;
      padding-top:5%;
  z-index: 9999;
  border: 0;
  font-size: 18px !important;
  line-height: 1.42857143 !important;
  letter-spacing: 4px;
  border-radius: 0;*/
  font-size: 1.05em !important;
  font-family: Roboto, Montserrat, sans-serif;
  background-color: #5f9ff7;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.navbar li a, .navbar {
  color: #ffffff !important;
}

.navbar-nav > li > a {
  padding-top: 0.6em !important;
  padding-bottom: 0.6em !important;
  line-height: 22px;
}

.nav > li > a {
  padding-left: 12px;
  padding-right: 12px;
}

.navbar-header button {
  margin-top: 0.1em !important;
  margin-bottom: 0.2em !important;
}

.navbar-fixed-top {
  min-height: 0px;
}

ul.navbar-left {
  margin-left: 20.5%;
  margin-right: 0%;
}

ul.navbar-right {
  margin-left: 0%;
  margin-right: 0%;
}

a.navbar-brand {
  position: absolute;
  padding-top: 0;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  width: 20%;
  max-width: 240px;
  max-height: 70px;
  min-width: 120px;
  min-height: 20px;
  z-index: 1500;
}

a.navbar-brand img {
  background-color: rgba(255, 255, 255, 0.95);
  padding: 0.1em 0.1em 0.1em 0.1em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.navbar-header #homeButton {
  position: absolute;
  top: 0.37em !important;
  left: 1em;
  display: block;
}

.collapseNavButton {
  float: right;
  margin-top: -0.2em;
  margin-bottom: -0.3em;
  margin-right: -0.3em;
  padding: 0em !important;
}

.collapseNavButton > a:first-child {
  display: inline-block;
  padding: 0.6em !important;
}

.collapseNavButton:hover, .collapseNavMenu:hover, .collapseNavMenu:focus {
  background: transparent !important;
}

.collapseNavButton:hover span, .collapseNavMenu:hover span, .collapseNavMenu:focus span, #homeButton:hover span, #homeButton:focus span {
  color: #1E2D4D !important;
}

.collapseNavButton:hover a, .collapseNavMenu:hover a, .collapseNavMenu:focus, .collapseNavButton a:active, .collapseNavButton a:focus, .collapseNavMenu:focus a {
  text-decoration: none !important;
}

.navbar-default .navbar-toggle:hover .icon-bar {
  background-color: #1E2D4D;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: white;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}

/* back-up
a.navbar-brand{
    background-color:rgba(255,255,255,0.95);
    position:fixed;
    padding-top:0.25%;
    padding-bottom: 7.6%;
    padding-left:25px;
    padding-right:5px;
    width:25%;
    max-width:300px;
    max-height: 100px;
    min-width:200px;
    min-height:60px
}*/
#topLogo:hover {
  background-color: #5f9ff7;
}

#topLogo img {
  display: inline-block;
  width: 100%;
}

.navbar-fixed-top {
  border: #F7941E;
  margin: 0px;
}

.navbar-nav li.active a {
  color: #1E2D4D;
  background-color: #fff !important;
}

.navbar li a:focus {
  background-color: #5f9ff7 !important;
}

.navbar-nav > li.open > a {
  background-color: #5F9FF7 !important;
}

.navbar li a:hover {
  background-color: #1E2D4D !important;
  color: white !important;
}

.navbar-default .navbar-toggle {
  border-color: transparent;
  color: #fff !important;
}

#myNavbar {
  /* padding-top:2.5%;*/
  border: none;
}

.jumbotron {
  background: url("/img/main.jpg") no-repeat center top fixed;
  background-size: cover;
  background-origin: content-box;
  background-attachment: fixed;
  padding-top: 1em;
  /*    background-color:black;*/
  height: 700px;
  font-family: Lato, Roboto, Montserrat, sans-serif;
  text-shadow: 2px 2px 4px #808080;
  margin-bottom: 0px;
}

.jumbotron h1, .jumbotron .glyphicon {
  color: #fff;
  font-weight: 600;
  font-size: 3em;
  line-height: 1.2em;
  text-transform: uppercase;
}

.darkStripe {
  background-color: #414042;
  height: 85px;
}

.horizontalBanner {
  background: url("../images/bgsearch.jpg") no-repeat center top;
  background-size: cover;
  height: 120px;
}

.logo-small {
  color: #F7941E;
  font-size: 50px;
}

.logo {
  color: #F7941E;
  font-size: 200px;
}

.portrait {
  max-width: 170px !important;
  max-height: 170px !important;
}

.schoolLogo {
  width: 100% !important;
  height: auto !important;
  width: 120px !important;
  max-height: 200px !important;
}

.thumbnail {
  padding: 0 0 15px 0;
  border: none;
  border-radius: 0;
  background-color: #ffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
}

.thumbnail img {
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
}

#team .thumbnail img {
  width: 70%;
  height: 70%;
  padding-top: 20px;
}

.thumbnail p {
  padding: 10px 15px 0px 15px;
}

.tab-content {
  background-color: white;
  border-left: 1px solid lightgrey;
}

.carousel-control.right, .carousel-control.left {
  background-image: none;
  color: #f4511e;
}

.carousel-indicators li {
  border-color: #f4511e;
}

.carousel-indicators li.active {
  background-color: #f4511e;
}

.item h4 {
  font-size: 19px;
  line-height: 1.375em;
  font-weight: 400;
  font-style: italic;
  margin: 70px 0;
}

.item span {
  font-style: normal;
}

/*  .panel:hover {
      box-shadow: 5px 0px 40px rgba(0,0,0, .2);
  }
  .panel-footer .btn:hover {
      border: 1px solid #f4511e;
      background-color: #fff !important;
      color: #f4511e;
  } */
.panel-title {
  font-size: 1.05em !important;
  display: block;
}

.backToMain {
  float: right;
  margin-top: 1em;
}

.myPanel {
  border-color: #5f9ff7;
}

.myPanel > .panel-heading {
  color: #ffffff !important;
  background-color: #7CAEFF !important;
  border-color: #5f9ff7;
}

/*
.panel-footer {
    background-color: white !important;
}
.panel-footer h3 {
    font-size: 32px;
}
.panel-footer h4 {
    color: #aaa;
    font-size: 14px;
}
.panel-footer .btn {
    margin: 15px 0;
    background-color: #f4511e;
    color: #fff;
}
*/
footer .glyphicon {
  font-size: 20px;
  margin-bottom: 20px;
  color: #5f9ff7;
}

footer ul {
  padding-left: 1.5em;
  /*list-style:none*/
}

footer h3 {
  color: #5C728C;
}

footer h4 {
  padding-left: 0.3em;
  font-size: 1.2em !important;
  color: #5C728C;
  margin-bottom: 6px;
}

footer ul li, footer ul li a {
  color: #5E4D46;
}

footer ul li a:hover {
  color: #2B4A70;
  text-decoration: none;
}

.cookie-consent {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9999;
  padding: 1em;
  margin: 0;
  background-color: #4972A0;
}

.cookie-consent .cookie-consent__message {
  color: #FEFEFE;
  font-size: 0.9em;
  font-weight: normal;
  margin-right: 50px;
}

.cookie-consent .cookie-consent__message a {
  color: yellow;
}

.cookie-consent .cookie-consent__agree {
  text-align: left;
}

.slideanim {
  visibility: hidden;
}

.slide {
  animation-name: slide;
  -webkit-animation-name: slide;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  visibility: visible;
}

@keyframes slide {
  0% {
    opacity: 0;
    -webkit-transform: translateY(70%);
    transform: translateY(70%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

@-webkit-keyframes slide {
  0% {
    opacity: 0;
    -webkit-transform: translateY(70%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
  }
}

.schoolPic {
  width: 100%  !important;
  height: 350px;
  margin: 0px  !important;
  -o-object-fit: cover  !important;
  object-fit: cover  !important;
}

.schoolMap {
  height: 350px;
  width: 100%;
}

th {
  vertical-align: middle !important;
}

td.midAlign, td.midAlign {
  vertical-align: middle !important;
}

td.noTopBorder {
  border-top: none !important;
}

.tab-content table {
  border-right: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
}

/*======= latestJobs ========*/
.filter-toggle {
  color: black !important;
  display: block !important;
}

.defaultBgColor {
  background-color: #5F9FF7 !important;
}

.midBlueBgColor {
  background-color: #96BEFF !important;
}

.lightBlueBgColor {
  background-color: #C9DDFF !important;
}

.whiteBgColor {
  background-color: #FFF !important;
}

.infoBgColor {
  background-color: #5bc0de !important;
}

.infoPanelColor {
  background-color: #D9EDF7 !important;
}

.successBgColor {
  background-color: #5cb85c !important;
}

.warningBgColor {
  background-color: #f0ad4e !important;
}

.transparent {
  background: transparent !important;
}

.lightYellowBgColor {
  background-color: #FFE9AA !important;
}

.lightGreenBgColor {
  background-color: #DFF0D8 !important;
}

.xLightGreenBgColor {
  background-color: #F4FFF4 !important;
}

.xLightBrownBgColor {
  background-color: #FFFBF7 !important;
}

.lightRedBgColor {
  background-color: #FFEFF6;
}

.lightGreyBgColor {
  background-color: #F7F7F7;
}

.dangerBgColor {
  background-color: #d9534f !important;
}

.primaryBgColor {
  background-color: #337ab7 !important;
}

.greyBgColor {
  background-color: #eeeeee !important;
}

.favBgColor {
  background-color: #5CB85C !important;
}

.brownBgColor {
  background-color: #8A6D3B !important;
}

.defaultFontColor {
  color: #5f9ff7 !important;
}

.glyphicon-h2 {
  font-size: 1.4em;
  -webkit-transform: translateY(25%);
  transform: translateY(25%);
  margin-top: -20px;
}

table.whiteBg tr {
  background-color: #FFFFFF;
}

table.whiteBg tr:hover {
  background-color: rgba(95, 159, 247, 0.1);
}

table.table-hover tr:hover {
  background-color: rgba(95, 159, 247, 0.1);
}

table.table tbody tr td {
  border-color: #e0e6ff;
}

table.jobList, table.latestJobs, table.twoColumnsFixed, table.fixedDoubleLine {
  border-collapse: separate;
  border-spacing: 0px 0.5em;
}

table.jobList td, table.latestJobs td, table.twoColumnsFixed td, table.fixedDoubleLine td {
  border-top: 1px solid #e0e6ff !important;
  border-bottom: 1px solid #e0e6ff;
}

table.twoColumns td:nth-child(2) {
  width: 75%;
}

table.twoColumnsArticle td:nth-child(2) {
  width: 77%;
}

table.twoColumnsFixed {
  table-layout: fixed;
}

table.twoColumnsFixed td:nth-child(2) {
  width: 50%;
}

table.fixedDoubleLine {
  table-layout: fixed;
}

.tab-content table td, .tab-content table {
  border-color: #ddd !important;
}

.blue-tooltip .tooltip.top .tooltip-inner {
  background-color: #3B579D;
}

.blue-tooltip .tooltip.top .tooltip-arrow {
  border-top-color: #3B579D;
}

.blue-tooltip .tooltip.in {
  opacity: 1 !important;
}

.red-tooltip .tooltip.top .tooltip-inner {
  background-color: #A94442;
  opacity: 1.0 !important;
}

.red-tooltip .tooltip.top .tooltip-arrow {
  border-top-color: #A94442;
}

.red-tooltip .tooltip.in {
  opacity: 1 !important;
}

/*.tab-content{
    border-right:1px solid #ddd !important;
    border-bottom:1px solid #ddd !important;
}*/
.verticalCheckboxWrapper {
  padding-left: 2.2em !important;
}

table.jobList td.leftColumn {
  border-left: 1px solid #e0e6ff;
}

table.latestJobs td.rightColumn {
  position: relative;
}

table.jobList td.rightColumn {
  position: relative;
  border-right: 1px solid #e0e6ff;
}

table {
  border-color: #e0e6ff !important;
}

table.latestJobs {
  border-bottom: 1px solid #e0e6ff;
}

.latestJobs img, .jobList img, #schoolProfile_tab img {
  width: 100px;
}

.jobList ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.jobList li {
  display: inline-block;
  padding: 0px 0px;
  width: 300px;
  font-size: 0.9em;
}

.blog {
  box-shadow: 0 2px 4px 0 rgba(95, 159, 247, 0.2), 0 3px 10px 0 rgba(95, 159, 247, 0.19);
  padding: 5px;
  margin: 0px 0px 20px 0px;
  position: relative;
}

div.blog-hover:hover {
  background-color: rgba(95, 159, 247, 0.1);
}

.clickable-blog {
  position: absolute;
  width: 98%;
  height: 96.5%;
  display: block;
}

#userDisplay {
  position: relative;
  color: #AFCBFF;
  float: right;
  top: 37px;
  padding: 0.8em 0.5em 0em 0.5em;
  z-index: 100;
}

#userDisplay p {
  font-size: 0.9em;
  text-shadow: 2px 2px 4px #808080;
}

.rightBanner img {
  width: 100%;
  length: auto;
}

.uploadWrapper {
  padding-top: 0.4em;
}

.uploadWrapper label {
  background-color: #EDF4FF;
  border-color: #5f9ff7;
  font-weight: normal;
}

#searchForm {
  padding: 0 2em;
}

.label {
  padding: 0.15em 0.5em;
}

.clickable-row, .linkLike {
  cursor: pointer;
}

.center {
  text-align: center;
}

.rightText {
  text-align: right;
}

.cornerMarker {
  position: absolute;
  right: 0.5em;
  top: 0.5em;
  opacity: 0.5;
}

.loader {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1000;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #F7941E;
  width: 120px;
  margin-top: -60px;
  margin-left: -60px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  display: none;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#submitSuccess {
  display: none;
}

.resultMarker {
  font-size: 0.9em;
  line-height: 2em;
  opacity: 0.7;
}

.icon-large {
  font-size: 1.4em;
}

.icon-med {
  font-size: 1.1em;
}

input[type="checkbox"], input[type="radio"] {
  margin-top: 0.1em;
  width: 1.1em;
  height: 1.1em;
}

.selectChild {
  margin-left: 3px !important;
}

.floatLeft {
  float: left;
}

.floatRight {
  float: right;
}

.selectAll {
  margin-left: 12px;
  margin-right: 9px;
}

.selectAllInTable {
  margin: 0px 0px 0px 3px;
}

.navbar .dropdown-menu {
  background-color: #5f9ff7;
  border-top: 2px solid #5f9ff7;
  font-size: 0.92em;
  margin-top: -4px;
}

/*.dropdown:hover .dropdown-menu-right {
    display: block;
}*/
.dropdown-menu-right {
  margin-top: -1px;
}

.checkbox {
  cursor: pointer;
}

input[type="checkbox"]:hover {
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.075) inset, -1px 0 4px #6ba8ff;
  outline: 0 none;
}

.checkbox:hover {
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.075) inset, 2px 2px 1px rgba(107, 168, 255, 0.5);
  outline: 0 none;
}

.checkbox-danger {
  width: 1.75em;
  height: 1.75em;
  position: relative;
  margin: 0em auto;
  background: #fcfff4;
  border-radius: 0.25em;
  /* background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
     background: linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);*/
  box-shadow: inset 0px 1px 1px white, 0px 1px 2px rgba(0, 0, 0, 0.5);
}

.checkbox-danger label {
  width: 1.375em;
  height: 1.375em;
  cursor: pointer;
  position: absolute;
  left: 0.1875em;
  top: 0.1875em;
  background: #d9534f;
  opacity: 0.4;
  border-radius: 0.125em;
  /* background: linear-gradient(top, #222 0%, #d9534f 100%);*/
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px white;
}

.checkbox-danger label:after {
  content: '';
  width: 1em;
  /*9px*/
  height: 0.5625em;
  /*5px*/
  position: absolute;
  top: 0.25em;
  left: 0.25em;
  border: 0.25em solid #fcfff4;
  border-top: none;
  border-right: none;
  background: transparent;
  opacity: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.checkbox-danger label:hover::after {
  opacity: 0.0;
}

.checkbox-danger input[type=checkbox] {
  visibility: hidden;
}

.checkbox-danger input[type=checkbox]:checked + label:after {
  opacity: 1;
}

.checkbox-default {
  width: 1.75em;
  height: 1.75em;
  position: relative;
  margin: 0em auto;
  background: #F7F7F7;
  border-radius: 0.25em;
  /* background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
     background: linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);*/
  box-shadow: inset 0px 1px 1px white, 0px 1px 2px rgba(0, 0, 0, 0.5);
}

.checkbox-default label {
  width: 1.375em;
  height: 1.375em;
  cursor: pointer;
  position: absolute;
  left: 0.1875em;
  top: 0.1875em;
  background: #ffffff;
  opacity: 0.4;
  border-radius: 0.125em;
  /* background: linear-gradient(top, #222 0%, #ffffff 100%);*/
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px white;
}

.checkbox-default label:after {
  content: '';
  width: 1em;
  /*9px*/
  height: 0.5625em;
  /*5px*/
  position: absolute;
  top: 0.25em;
  left: 0.25em;
  border: 0.25em solid #191919;
  border-top: none;
  border-right: none;
  background: transparent;
  opacity: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.checkbox-default label:hover::after {
  opacity: 0.0;
}

.checkbox-default input[type=checkbox] {
  visibility: hidden;
}

.checkbox-default input[type=checkbox]:checked + label:after {
  opacity: 1;
}

/*
.checkbox-success {
    width: 1.75em;
    height: 1.75em;
    position: relative;
    margin: 0em auto;
    background: #fcfff4;
    border-radius: 0.25em;
    box-shadow: inset 0px 1px 1px white, 0px 1px 2px rgba(0, 0, 0, 0.5);
}
.checkbox-success label {
    width: 1.375em;
    height: 1.375em;
    cursor: pointer;
    position: absolute;
    left: 0.1875em;
    top: 0.1875em;
    background:#5CB85C;
    opacity:0.4;
    border-radius: 0.125em;
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px white;
}
.checkbox-success label:after {
    content: '';
    width: 1em;
    height: 0.5625em;
    position: absolute;
    top: 0.25em;
    left: 0.25em;
    border: 0.25em solid #fcfff4;
    border-top: none;
    border-right: none;
    background: transparent;
    opacity: 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.checkbox-success label:hover::after {
    opacity: 0.3;
}
.checkbox-success input[type=checkbox] {
    visibility: hidden;
}
.checkbox-success input[type=checkbox]:checked + label:after {
    opacity: 1;
}


 //green check-box whole box type
.checkbox-success {
  width: 1.77em;
  height: 1.81em;
  position: relative;
  margin: 0em auto;
  background: #fcfff4;
  border-radius: 0.25em;
  box-shadow: inset 0px 1px 1px white, 0px 1px 2px rgba(0, 0, 0, 0.5);
}
.checkbox-success label {
  width: 1.375em;
  height: 1.375em;
  cursor: pointer;
  position: absolute;
  left: 0.1875em;
  top: 0.1875em;
  background:#fcfff4;
  opacity:0.4;
    border-radius: 0.125em;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px white;
}
.checkbox-success label:after {
  content: '';
  width: 1.375em;
  height: 1.375em;
  position: absolute;
  top: 0em;
  left: 0em;
  border: 0.731em solid #5CB85C;
  border-radius: 0.125em;
  background: transparent;
  opacity: 0;
}
.checkbox-success label:hover::after {
  opacity: 0.3;
}
.checkbox-success input[type=checkbox] {
  visibility: hidden;
}
.checkbox-success input[type=checkbox]:checked + label:after {
  opacity: 1;
}
*/
.checkbox-success {
  width: 1.75em;
  height: 1.75em;
  position: relative;
  margin: 0em auto;
  background: #F7F7F7;
  border-radius: 0.25em;
  /* background: -webkit-linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
     background: linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);*/
  box-shadow: inset 0px 1px 1px white, 0px 1px 2px rgba(0, 0, 0, 0.5);
}

.checkbox-success label {
  width: 1.375em;
  height: 1.375em;
  cursor: pointer;
  position: absolute;
  left: 0.1875em;
  top: 0.1875em;
  background: #ffffff;
  opacity: 0.4;
  border-radius: 0.125em;
  /* background: linear-gradient(top, #222 0%, #ffffff 100%);*/
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px white;
}

.checkbox-success label:after {
  content: '';
  width: 1em;
  /*9px*/
  height: 0.5625em;
  /*5px*/
  position: absolute;
  top: 0.25em;
  left: 0.25em;
  border: 0.25em solid #5CB85C;
  border-top: none;
  border-right: none;
  background: transparent;
  opacity: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.checkbox-success label:hover::after {
  opacity: 0.0;
}

.checkbox-success input[type=checkbox] {
  visibility: hidden;
}

.checkbox-success input[type=checkbox]:checked + label:after {
  opacity: 1;
}

select:invalid {
  color: #999999;
}

a:focus {
  outline: none;
}

.textBelowHeading {
  display: inline-block;
  clear: both;
  float: left;
}

.allRight {
  float: right;
  clear: right;
}

.allLeft {
  float: left;
  clear: both;
  display: inline-block;
}

.hidden {
  display: none;
}

.form-horizontal {
  clear: right;
}

.modal {
  z-index: 3000;
}

.clearBoth {
  clear: both;
}

#myFilter .col-sm-4 {
  text-align: left !important;
}

.favoriteColor {
  background-color: rgba(92, 184, 92, 0.6);
  color: #ffffff;
  border-color: transparent;
}

.filterApplicants {
  margin-right: 0.5em;
  margin-bottom: 0.3em;
}

.badge {
  margin-top: -0.3em !important;
}

#myFilter label.checkbox {
  font-weight: normal;
  font-size: 0.94em;
}

#myFilter .panel-body {
  padding: 0em 0em 0em 2em;
}

.filterButton {
  margin-top: 0.4em;
}

.tableLeftGreyBorder {
  border-left: 1px solid #ddd !important;
}

.tableRightGreyBorder {
  border-right: 1px solid #ddd !important;
}

.tableTopGreyBorder {
  border-top: 1px solid #ddd !important;
}

.tableBottomGreyBorder {
  border-bottom: 1px solid #ddd !important;
}

.regularFont {
  font-weight: normal;
}

a.latestJobsLink {
  display: block;
  position: absolute;
  z-index: 1;
  width: 57%;
  height: 8%;
}

a.schoolLink:hover {
  color: #FFC43D !important;
}

a.clickable-td {
  position: absolute;
  width: 90%;
  height: 96.5%;
  display: block;
}

a.clickable-td-latest-jobs {
  position: absolute;
  width: 98%;
  height: 96.5%;
  display: block;
}

a.clickable-td:link {
  color: rgba(255, 255, 255, 0.5) !important;
}

a.clickable-td:visited {
  border-color: blue !important;
}

/* active tab color */
/*.nav-tabs>li.jobTab.active>a, .nav-tabs>li.jobTab.active>a:hover, .nav-tabs>li.jobTab.active>a:focus {
  color: #ffffff !important;
  background-color: #89B6F4 !important;
}*/
.innerBtn {
  position: relative;
}

.biggerLink:after {
  content: "";
  padding: 1em 2em 1em 2em;
  position: absolute;
  left: -2em;
  top: -1em;
}

.biggerLink:active, .biggerLink:focus {
  outline: none !important;
}

br.bigBreak {
  line-height: 2.4em;
}

.jobList .checkbox-danger {
  margin-left: -0.2em;
  margin-top: -0.2em;
  margin-bottom: 0.8em;
  float: left;
}

.seekerBinBox {
  margin-left: 0.4em;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  margin-right: 0.3em;
  float: left;
}

.lightRedFont {
  color: #A94442 !important;
  opacity: 0.3;
}

.schoolRemoveButton {
  background-color: rgba(217, 83, 79, 0.6);
  color: #ffffff;
  border-color: transparent;
}

.checkbox-inline, .checkbox {
  font-weight: normal;
}

.fileSelector {
  display: none !important;
}

.leftPadding2em {
  padding-left: 2em;
}

.zeroMargin {
  margin: 0;
}

.panel-danger textarea:focus,
.panel-danger input[type="text"]:focus,
.panel-danger input[type="password"]:focus,
.panel-danger input[type="datetime"]:focus,
.panel-danger input[type="datetime-local"]:focus,
.panel-danger input[type="date"]:focus,
.panel-danger input[type="month"]:focus,
.panel-danger input[type="time"]:focus,
.panel-danger input[type="week"]:focus,
.panel-danger input[type="number"]:focus,
.panel-danger input[type="email"]:focus,
.panel-danger input[type="url"]:focus,
.panel-danger input[type="search"]:focus,
.panel-danger input[type="tel"]:focus,
.panel-danger input[type="color"]:focus,
.panel-danger .uneditable-input:focus {
  border-color: rgba(255, 181, 192, 0.8);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(255, 181, 192, 0.6);
  outline: 0 none;
}

button.lightDanger {
  background-color: #f2dede;
  border-color: #ebccd1;
}

button.lightDanger:hover {
  background-color: #FFC9D6;
  border-color: #ebccd1;
}

button.lightDanger:active,
button.lightDanger:active:focus,
button.lightDanger:active:hover {
  background-color: #f2dede;
  border-color: #ebccd1;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(255, 181, 192, 0.6);
  outline: 0 none;
}

.panel-danger a {
  color: #A94442 !important;
}

button.lightPrimary {
  background-color: #7CAEFF;
  border-color: #5f9ff7;
}

.warning .popover-title {
  background-color: #f0ad4e;
  color: white;
}

.brown .popover-title {
  background-color: #8A6D3B;
  color: white;
}

.info .popover-title {
  background-color: #5bc0de;
  color: white;
}

.danger .popover-title {
  background-color: #d9534f;
  color: white;
}

.success .popover-title {
  background-color: #5cb85c;
  color: white;
}

.default .popover-title {
  background-color: #5f9ff7;
  color: white;
}

.popButton {
  display: inline-block;
}

.popButton, .popButton:active, .popButton:focus {
  background-color: rgba(234, 251, 255, 0) !important;
  border: none !important;
  outline: none !important;
  padding-left: 0.2em;
  padding-right: 0.2em;
}

a.jobPositionLink:hover, a.jobPositionLink:active, a.articleTitleLink:hover, a.artitleTitleLink:active {
  text-decoration: none;
}

/*@media (min-width: 900px) {
    .navbar .navbar-right {
        font-size:85% !important;
    }
}*/
@media (max-width: 992px) {
  ul.navbar-left {
    margin-left: 16.5%;
    margin-right: 0%;
  }
  a.navbar-brand {
    max-width: 150px;
  }
}

@media (min-width: 992px) {
  .xLargeHide {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .navbar-header .collapseNavButton .dropdown-menu {
    margin-top: -0.4em;
  }
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }
  .navbar-nav .open .dropdown-menu > li > a {
    line-height: 20px;
  }
  .navbar-nav .open .dropdown-menu > li > a,
  .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 5px 15px 5px 25px;
  }
  .navbar-nav .open .dropdown-menu > li > ul > li > a {
    padding-left: 38px !important;
    font-size: 0.94em !important;
  }
  .navbar-nav .dropdown-menu > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
  }
  .navbar-header {
    float: none;
  }
  table.latestJobs img.schoolLogo {
    width: 110px !important;
    max-height: 188px !important;
  }
  .navbar-left, .navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    margin-top: 2em;
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-nav {
    float: none !important;
  }
  /*   .navbar-collapse:after {
       content:'';
       position: absolute;
       top:100%;
       left:50%;
       width: 100%;
       height: 40px;
       border:1px solid #red;
       background-color: #5f9ff7;
       transform:translateX(-50%);
   }*/
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 7px;
  }
  .nav > li > a {
    padding-left: 20px;
  }
  .collapse.in {
    display: block !important;
  }
  ul.navbar-left {
    margin-left: 0px;
  }
  ul.navbar-right {
    margin-left: 0px;
  }
  .collapse-md {
    display: none;
  }
  .largeHide {
    display: none !important;
  }
  table.jobList {
    table-layout: fixed;
  }
  table.jobList td:nth-child(2) {
    width: 77%;
  }
  .logo {
    font-size: 160px;
  }
  .col-sm-3-thumbnail {
    padding-left: 0.2em;
    padding-right: 0.2em;
  }
}

@media screen and (max-width: 767px) {
  .col-sm-4 {
    text-align: center;
    margin: 25px 0;
  }
  table.jobList {
    table-layout: fixed;
  }
  table.jobList td:nth-child(2) {
    width: 77%;
  }
  table.jobList td:nth-child(1) {
    max-width: 200px;
  }
  img.schoolLogo {
    width: 110px !important;
    max-height: 188px !important;
  }
  .btn-lg {
    width: 100%;
    margin-bottom: 0.5em;
  }
  .container-fluid {
    margin: 1em 2.1%;
  }
  .schoolMap {
    height: 300px;
  }
  .schoolPic {
    height: 300px;
  }
  .medHide {
    display: none !important;
  }
  .filterTopRightPanel {
    margin-top: -1.3em !important;
  }
}

@media screen and (min-width: 480.1px) {
  .smallShow {
    display: none !important;
  }
}

@media screen and (max-width: 480px) {
  .logo {
    font-size: 150px;
  }
  .container-fluid {
    margin: 1em 5%;
  }
  img.schoolLogo {
    width: 95px !important;
    max-height: 160px !important;
  }
  img.userPhoto {
    width: 95px !important;
    max-height: 160px !important;
  }
  table.jobList {
    table-layout: fixed !important;
  }
  table.jobList td:nth-child(2) {
    width: 99% !important;
  }
  a.latestJobsLink {
    width: 95%;
  }
  h3.applicantName {
    font-size: 1.4em;
  }
  .icon-large {
    font-size: 1.4em !important;
  }
  .btn-lg {
    font-size: 100% !important;
  }
  .input-lg {
    font-size: 97% !important;
    height: 3em !important;
  }
  .cornerMarker {
    right: 0.2em !important;
    top: 0.2em !important;
  }
  input[type="checkbox"], input[type="radio"] {
    width: 1em;
    height: 1em;
  }
  .schoolMap {
    height: 250px;
  }
  .schoolPic {
    height: 250px;
  }
  .smallHide {
    display: none !important;
  }
  .checkbox-danger {
    -webkit-transform: scale(0.8, 0.8) !important;
    transform: scale(0.8, 0.8) !important;
  }
  .breakAll {
    word-break: break-all;
  }
  .xsm-tab {
    border-right: none !important;
  }
  .exercise-wrapper .media .media-body div.exercise-question {
    padding-top: 0.4em;
  }
  .exercise-wrapper .media .media-body div.exercise-question-image {
    padding-top: 2em;
  }
}

/*# sourceMappingURL=app.css.map */
